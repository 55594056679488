@import "@/assets/styles/variables.scss";



































.loading .back-button {
  display: none;
}

.back-button {
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;

  &:before {
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-bottom: 1px solid #000000;
    border-left: 1px solid #000000;
    transform: rotate(45deg);
    margin: 0 5px;
  }
}

.is-mobile,
.is-tablet {
  .back-button {
    line-height: 36px;
  }
}

.is-mobile {
  .back-button {
    &:before {
      width: 14px;
      height: 14px;
      border-bottom: 2px solid #000000;
      border-left: 2px solid #000000;
      transform: rotate(45deg);
      margin-right: 5px;
    }
  }
}
