@import "@/assets/styles/variables.scss";






























































































































































































.dynamic-image {
  opacity: 1;
  /*display: flex;
  flex-direction: column;*/
}

.dynamic-image-image {
  transition: opacity 100ms 200ms, height 100ms 0ms ease-in;
  opacity: 1;
  width: 100%;
}

.forced {
  .dynamic-image-image {
    transition: 0ms;
    opacity: 1;
  }
}

.dynamic-image-loading:not(.forced) {
  background: url('/img/spinner.gif') no-repeat center center / 50px 50px;
  height: 50px;
  width: 100%;
  opacity: 0.2;

  .dynamic-image-image {
    height: 100%;
    width: 100%;
    opacity: 0;
  }
}
