@import "@/assets/styles/variables.scss";
































































































































#routeLoader {
  min-height: 100%;
  min-width: 100%;

  svg {
    margin: auto;
    height: 100%;
    width: auto;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .4);
  border-radius: 0px;
  outline: none
}

.is-desktop {
  .talent-box, .projet-box {
    h1 {
      min-width: 0;
      max-width: 60%;
      margin: auto !important;
      text-transform: none;
    }
  }
}

.is-tablet {
  .talent-box, .projet-box {
    h1 {
      text-transform: none;
      font-size: 40px;
    }
  }
}

.is-mobile {
  .talent-box, .projet-box {
    h1 {
      text-transform: none;
      font-size: 30px;
    }
  }
}
