@import "@/assets/styles/variables.scss";















































.logo {
  .logo-logo, path {
    transition: 250ms ease-out;
  }

  path {
    fill: black;
  }
}

.logo-link {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.fill-grey {
  path {
    fill: #f3f3f3;
  }
}
