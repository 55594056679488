/* Dropdown Menu */

$border-radius: 8px;
$box-shadow: none;

$bg-color: white;
$z-index: 1000;
$min-width: 160px;
$max-height: 350px;

$vs-state-active-bg: #333;
$vs-state-active-color: white;
$vs-state-disabled-color: #bbb;
.vs__dropdown-menu {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% - 1px); // -{#$border-width} here ensures the left and right borders of the dropdown appear flush with the toggle.
  left: 0;
  z-index: 1000;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: $max-height;
  min-width: $min-width;
  overflow-y: auto;
  box-shadow: $box-shadow;
  border: 0px solid black;
  border-top-style: none;
  border-radius: 0 0 $border-radius $border-radius;
  text-align: left;
  list-style: none;
  background: $bg-color;

  li {
    margin: 0 10px 10px 0;
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    padding: 5px 10px;
    color: white;
  }
}

.vs--searching .vs__dropdown-menu {
  display: flex;
}

.vs__no-options {
  text-align: center;
}

/* List Items */
.vs__dropdown-option {
  line-height: 1.42857143; /* Normalize line height */
  display: block;
  clear: both;
  white-space: nowrap;
  background: white !important;
  color: black !important;
  border: 1px solid black;
  border-radius: 100px;
  font-weight: 700;
  padding: 5px 10px !important;
  transition: background-color 150ms;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
}

.vs__dropdown-option:hover {
  background: #000 !important;
  color: white !important;
  transition: background-color 150ms;
}

.vs__dropdown-option--disabled {
  background: transparent;
  color: black;

  &:hover {
    cursor: inherit;
  }
}
