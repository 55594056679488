@import "@/assets/styles/variables.scss";


















































































































































































































@import "~@/assets/styles/_lists.scss";

.pagination-list {
  @extend .inline-list;
  @extend .flex-center;
  margin: 40px 0;
  user-select: none;
}

.pagination-item {
  min-width: 40px;
  max-width: 40px;
}

.pagination-length {
  margin-right: 0;
  margin-left: 5px;

  a {
    font-weight: 500;
    padding: 15px 5px;
    font-size: 15px;
    white-space: nowrap;

    &.active {
      opacity: 0;
    }
  }
}

.pagination-button {
  font-size: 16px;
  font-weight: 500;
  padding: 5px 5px;
  font-size: 15px;
  line-height: 1.5;
  text-align: center;
  display: inline-block;

  &:hover {
    font-weight: 600;
    text-decoration: none;
  }

  &.inactive {
    pointer-events: none;
    //color: $pale-grey
  }
}

.pagination-list {
  position: relative;
  margin: 0;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-align: center;

  ul {
    transition: 250ms;
    max-width: 400px;
  }

  li {
    display: inline-block;
    margin: 0;

  }

  .pagination-first {
    margin-right: 0;
    max-width: 80px;

    &:after {
      @extend .injectDot;
      text-align: right;
    }

    &.current {
      &:before {
        @extend .injectCurrent;
      }
    }
  }

  .pagination-last {
    margin-left: 0;

    &:before {
      @extend .injectDot;
      text-align: left;
    }

    &.current {
      &:after {
        @extend .injectCurrent;
      }
    }
  }

  .pagination-previous {
    margin-right: 60px;
  }

  .pagination-next {
    margin-left: 60px;
  }

  .pagination-previous, .pagination-next {
    min-width: 0;
    max-width: none;

    .pagination-button {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

}

.pagination-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  margin: 70px 0 40px;
  overflow: hidden;

  &.active {
    transition: 250ms;
    opacity: 1;
    //height: 42px;
  }

  &.inactive {
    transition: 0ms;
    opacity: 0;
  }
}

.pagination-box.short, .is-mobile.is-portrait .pagination-box {
  .pagination-list {
    .pagination-first:after {
      @extend .injectNoDot
    }

    .pagination-last:before {
      @extend .injectNoDot
    }
  }
}

.injectDot {
  content: '...';
  width: 22px;
  display: inline-block;
}

.injectNoDot {
  content: '';
  width: auto;
}

.injectCurrent {
  content: '';
  position: absolute;
  height: 3px;
  left: -2px;
  right: -2px;
  bottom: 0px;
  background-color: black;
}

.pagination-button.current {
  position: relative;
  font-weight: 700;


  &:after {
    @extend .injectCurrent;
  }
}

.is-mobile.is-landscape {
  .pagination-previous, .pagination-next {
    font-family: monospace;
    margin-right: 0;
    margin-left: 0;
  }

  .pagination-item {
    min-width: 30px;
    max-width: 30px;
  }

  .pagination-button {
    padding: 5px 0;
  }
}

.pagination-list-dynamics ul.no-fx {
  transition: 0ms;
}
