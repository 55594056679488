main h1 {
  font-size: 45px;
  font-weight: 400;
  margin: 0 0 80px;
  text-transform: none;
  min-width: 100%;
  text-align: center;



  &.sr-only {
    min-width: auto;
    max-width: none;
  }
}
