@import "@/assets/styles/variables.scss";
@import "variables";
@import 'bootstrap_custom';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import "elements";
@import "lists";
@import "structure";
@import "hn";
@import "fx";
@import "vueSelectCustom";

html, body {
  font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: black;
  min-width: 320px;
  overflow-x: hidden;
  overflow-y: auto;

  &.loading {
    overflow-y: scroll;
  }

  &.loaded {
    overflow-y: scroll;
  }
}
html.panel-open {
  overflow: hidden;
}

