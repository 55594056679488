@import "@/assets/styles/variables.scss";































































































































































.talents {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 36px;
  text-align: center;
  max-width: 1260px;
  margin: 0 auto;

  &.talents-grid-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.talents-grid-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &.talents-grid-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.talent {
  margin-bottom: 70px;
  width: 240px;
}

.talent-avatar {
  background-size: cover;

  img.dynamic-image-loaded {
    width: 100%;
  }
}

.talent-name {
  display: block;
  font-size: 26px;
  margin-top: 20px;
}

.talent-type {
  font-size: 16px;
}

.talent-link:hover {
  text-decoration: none !important;
}

.talents-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease;
  list-style: none;
  margin: 50px 0;
  user-select: none;

  .inactive {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }


  .pagination-page {
    min-width: 23px;
    min-height: 37px;
    max-width: 23px;
    max-height: 37px;
    text-align: center;
    position: relative;
    display: block;
    cursor: pointer;
    margin-right: 40px;

    &:last-of-type {
      margin-right: 0;
    }

    &.current, &:hover {
      background: #D6F2C7
    }

    & > span {
      position: relative;
      z-index: 1;
      line-height: 37px;
      min-width: 23px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
  opacity: 1;
  transform: scale(1);
}

.fade-enter, .fade-leave-to
  /* .fade-leave-active avant la 2.1.8 */
{
  opacity: 0;

}

.no-talent {
  font-size: 32px;
  font-weight: 500;
  margin: 0px 0;
  text-align: center;
}

.responsive-grid-column {
  .talent {
    width: 100%;
    max-width: 320px;
    margin: 0 auto 70px;
  }
}

.responsive-cols-1,
.responsive-cols-2 {
  .talent {
    margin: 0 auto 35px;
  }
}

#talents-box {
  text-align: center;
  padding-bottom: 11px;

  .dcr-card .dynamic-image-image {
    width: 100%;
  }
}
