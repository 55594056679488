@import "@/assets/styles/variables.scss";




























































































































































































































































































































































































































































#searchButton {
  flex: 1;

  .vs__dropdown-toggle {
    border: none;
  }

  .vs__actions {
    display: none;
  }

  .vs__selected {
    font-size: 14px;
    font-weight: 600;
    background-color: white;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 400px;
    color: black;
    white-space: nowrap;
    margin: 0px 10px 10px 0;

    svg {
      margin-left: 10px;
    }
  }

  .vs__dropdown-toggle {
    padding: 0;
  }

  .vs__dropdown-menu {
    width: 700px !important;
    margin-top: 40px;
    border-radius: 0;
    // border: 1px solid rgba(0,0,0,.2) !important;
    //border: 2px solid #FAF7C0 !important;
    border-top: none !important;

    ul {
      padding: 0;
    }

    li {
      background-color: white;
      border: 1px solid black;
      padding: 10px 20px;
      border-radius: 100px;
      font-size: 14px;
    }
  }


  .vs__deselect {
    margin: 0 0 0 .5em;

    path {
      fill: black;
    }
  }

  .vs__selected-options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    margin-left: 25px;


    input {
      border: none;
      padding: 2px;
      margin-right: 25px;
      color: $black;
      font-size: 16px;
      min-width: 3ex;
    }
  }
}

#searchTagsList {
  padding: 40px 0 0;

  .vs__dropdown-menu {
    position: static;
    border-radius: 0;
    display: flex;
    width: 100% !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-height: none;

    li {
      background-color: white;
      border: 1px solid black;
      padding: 10px 20px;
      border-radius: 100px;
      font-size: 14px;

      a {
        background: blue;
      }
    }
  }
}

.is-mobile {
  #searchButton {
    .vs__selected-options {
      .vs__selected {
        font-size: 18px;
        padding: 5px 10px;
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }
  }
}

.is-tablet {
  #searchButton {
    .vs__selected-options {
      .vs__selected {
        font-size: 18px;
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 4px;
      }
    }
  }
}

#searchBox.panel {
  padding-top: 60px;

  .filter-list-item {
    margin: 0 0 60px;
  }
}

.talents-filter {
  background-color: $pale-yellow;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 700px;
  max-width: fit-content;
  margin: 0 auto 0px;
  padding: 18px 0;

  &.active {
    #searchButton .vs__selected-options {
      margin-bottom: -10px;
    }

    #searchButton .vs__selected-options input {
      margin-top: -10px;
    }
  }

  #search {
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    outline: none;
    border-left: 1px solid $black;
    width: 100%;
    padding: 2px 25px;
    margin-right: 25px;
    color: $black;
    font-size: 16px;
  }

  #search::placeholder {
    color: $black;
    font-size: 16px;
  }

  .talents-filter-image {
    margin-right: 25px;
  }
}

.is-mobile,
.is-tablet {
  .talents-filter {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
  }
}

.filter-list {
  .filter-list-item {
    margin: 0 50px 50px;
    white-space: nowrap;
  }

  .filter-list-button {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 30px;
    -webkit-appearance: none;
    text-decoration: none;
    position: relative;

    &:last-of-type {
      margin-right: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      top: 50%;
      height: 31px;
      z-index: -1;
      background-image: url(~@/assets/images/background/filterSelected.jpg);
      background-repeat: no-repeat;
      background-size: 100% 31px;
      background-clip: padding-box;
      background-position: 10px center;
      transition: 50ms $easeOutExpo;
      transform: translateY(-50%);
      transform-origin: left center;
      clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    }

    &.active:after {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      transition: 150ms ease-in-out;
    }
  }
}

.is-mobile .filter-list {
  text-align: center;
  flex-direction: column;

  .filter-list-item {
    margin: 0 0 15px;
  }
}

.home {
  user-select: none;

  .logo-bottom {
    margin-top: 120px;
  }
}

#filteredTalentLength {
  font-size: 15px;
  font-weight: 400;
  margin-right: 20px;
}

.is-tablet,
.is-mobile {
  .searchBoxPanelBottom {
    display: block;
  }

  #filteredTalentLength {
    margin: 20px 20px 20px 0;
    display: block;
  }
}

#clearFieldButton {
  cursor: pointer;
  margin-right: 1em;

  path {
    stroke-width: 1px;
  }
}

.searchBoxPanelBaseline {
  text-align: center;
  font-size: 48px;
  margin-bottom: 60px;
}

.is-mobile .searchBoxPanelBaseline {
  font-size: 24px;
  margin-bottom: 40px;
}

.searchBoxPanelBottom {
  text-align: center;

  button {
    border: none;
    background-color: black;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    padding: 20px 60px;
  }
}

#searchBoxPanel {
  .logo {
    margin: 53px 40px 20px;
  }
}

#searchBoxPanelButton {
  margin-bottom: 40px;

  .btn {
    background-color: black;
    color: #fff;
    font-size: inherit;
    text-transform: uppercase;
  }
}

.is-mobile, .is-tablet {
  #talentPagination {
    margin-top: 40px;
  }

  #searchBoxPanelButton {
    margin-bottom: 20px;

    .btn {
      font-size: 20px;
    }
  }
}

#mobileSearchValidate {
  margin-top: 40px;
  text-align: center;

  .btn {
    background-color: black;
    color: #fff;
    font-size: 30px;
  }
}
