@import "@/assets/styles/variables.scss";













































































































































































































@import "~@/assets/styles/_lists.scss";

#footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  #footer-top {
    .list {
      text-align: center;

      li [href] {
        color: $black;
      }
    }
  }

  #clients {
    min-width: 100%;

    strong {
      text-align: center;
      font-size: 16px;
      min-width: 100%;
      display: block;
      font-weight: 600;
      margin-bottom: 40px;
    }

    .clients-list {
      li img {
        opacity: 0.5;
        filter: grayscale(100);
        transition: opacity 300ms;
      }

      li:hover img {
        opacity: 1;
        filter: grayscale(0);
      }
    }

  }

  #footer-links {
    min-width: 100%;
    margin-bottom: 60px;

    [href] {
      font-size: 12px;
      white-space: nowrap;
    }
  }

  #footer-separator {
    min-width: 65px;
    max-width: 65px;
    height: 1px;
    background-color: $black;
    margin: 40px auto;
  }
}

.clients-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-row-gap: 30px;
  margin-bottom: 30px;
}

.is-mobile .clients-list {
  grid-template-columns: repeat(2, 1fr);
}

.clients-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  position: relative;
}

.clients-list-item-name {
  position: absolute;
  bottom: -30px;
  opacity: 0;
  transition: 250ms;
  font-size: 12px;
  display: none;
}

.clients-list-item:hover .clients-list-item-name {
  opacity: 1;
}

.flip-list-move {
  transition: transform 1s;
}

.is-mobile {
  .clients-list-item {
    margin: 10px;
  }

  .clients-list-item-name {
    display: none;
  }
}

#clientMobile {
  display: block;
  width: 100%;
  height: 120px;
  position: relative;

  li {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: 300ms;
    opacity: 0;
    margin: 0;

    &.active {
      opacity: 1;
    }
  }
}
