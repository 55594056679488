@import "@/assets/styles/variables.scss";



















































$gapX: 34px;
$gapY: 17px;
$gap: 17px 34px;
.responsive-grid {
  display: inline-grid;
  grid-gap: $gap;
  grid-template-columns: 250px;
  margin: 0 auto;
}

@for $i from 2 through 6 {
  .responsive-fluid-cols-#{$i} {
    grid-template-columns: repeat($i, calc((100% / #{$i}) - ((#{$gapX} * (#{$i} - 1) / #{$i}))));
  }
}

@for $i from 1 through 6 {
  .responsive-cols-#{$i} {
    grid-template-columns: repeat($i, 225px);
  }
}

.responsive-cols-1 {
  grid-template-columns: auto;
}

.responsive-cols-2 {
  // grid-template-columns:repeat(2, auto);
}
