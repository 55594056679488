@import "@/assets/styles/variables.scss";












































































html.panel-open {
  overflow-y: hidden;
}


.panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  transition: transform 250ms ease-out;
  transform: translateX(100%);
  will-change: transform;
  background-color: white;

  &.active {
    transform: translateX(0);
    z-index: 1000;
  }

  .panel-content {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
  }

  .panel-close {
    position: absolute;
    z-index: 1001;
    top: 14px;
    right: 34px;
    background-color: transparent;
    border: none;
  }

}

#tetetet {
  right: 80px;

  g {
    stroke: red;
  }
}


