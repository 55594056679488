@import "@/assets/styles/variables.scss";
























































$offset: -10px;
.dcr-card {
  position: relative;
  cursor: pointer;
  text-align: left;


  .dcr-card-link {
    &:before {
      content: "";
      position: absolute;
      top: $offset;
      left: $offset;
      right: $offset;
      bottom: $offset;
      //
      border: 1px solid #f3f3f3;
      pointer-events: none;
      opacity: 1;
      transition: 350ms ease-out;
    }

    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: none;

      &:before {
        opacity: 1;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, .3);
      }
    }
  }
}

.dcr-card-link, .dcr-card-link:hover, .dcr-card-link:focus {
  text-decoration: none;
}

.dcr-card-title {
  font-size: 26px;
  margin-top: 20px;
  line-height: 1.2;
  font-size: 20px;
  font-weight: 500;
}

.dcr-card-title_title {
  font-size: 20px;
  font-weight: 500;
}

.dcr-card-text {
  font-size: 14px;
}

.dcr-card-image {
  width: 100%;
}

.responsive-grid-column .dcr-card:not(:last-child) {
  margin-bottom: 34px;
}
