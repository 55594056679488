@import "@/assets/styles/variables.scss";






































.loader {
  &.loader-inline {
    display: inline-flex;
  }
}
