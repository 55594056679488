@import "@/assets/styles/variables.scss";









































































#connexionForm {
  max-width: 100%;
  width: 400px;
  margin: 0 auto;

  .warn {
    text-align: center;
  }
}
