.nop-enter-active, .nop-leave-active {
  transition-duration: 0ms;
  transition-delay: 0ms;
}
.slide-enter-active, .slide-leave-active {
  transition-duration: 350ms;
  transition-delay: 0ms;
  opacity: 1;
  transform: translateX(0px);
}

.slide-leave-to /* .slide-leave-active below version 2.1.8 */
{
  transform: translateX(-100px);
  opacity: 0;
}

.slide-enter {
  transform: translateX(100px);
  opacity: 0;
}






.slideHeight-enter-active, .slideHeight-leave-active {


}

.slideHeight-leave-to /* .slideHeight-leave-active below version 2.1.8 */
{

}

.slideHeight-enter {

}









.scale-enter {
  //Starting state for enter. Added before element is inserted, removed one frame after element is inserted.
  transform: translateX(100px);
  opacity: 0;
}

.scale-enter-active, .scale-leave-active {
  transition-duration: 350ms;
  transition-delay: 0ms;
  transition-timing-function: ease-out;
}

.scale-enter-active {

}

.scale-enter-to {
  // Only available in versions 2.1.8+. Ending state for enter. Added one frame after element is inserted (at the same time v-enter is removed), removed when transition/animation finishes.
  transform: translateX(0px);
  opacity: 1;
}

.scale-leave {
  //Starting state for leave. Added immediately when a leaving transition is triggered, removed after one frame.
  transform: translateX(0px);
  opacity: 1;
}

.scale-leave-active {
  // Active state for leave. Applied during the entire leaving phase. Added immediately when leave transition is triggered, removed when the transition/animation finishes. This class can be used to define the duration, delay and easing curve for the leaving transition.
}

.scale-leave-to {
  // Only available in versions 2.1.8+. Ending state for leave. Added one frame after a leaving transition is triggered (at the same time v-leave is removed), removed when the transition/animation finishes.
  transform: translateX(-100px);
  opacity: 0;
}

