#app {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

#main {
  flex: 1;
  padding: 40px 0;
  transition: max-height 0ms 700ms, height 0ms 700ms;

  &.loading {
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
}

#header {
  position: relative;
}

#header, #main, #footer, #favoriteList {
  padding-left: 20px;
  padding-right: 20px;
}

.extend {
  margin-left: -20px;
  margin-right: -20px;
}


@media screen and (min-width: 600px) {
  #header, #main, #footer, #favoriteList {
    padding-left: 40px;
    padding-right: 40px;
  }
  .extend {
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .full {
    margin-left: -40px;
    margin-right: -40px;
  }
}

@media screen and (min-width: 1260px) {
  $offset: calc((100% - 1260px) / 2);
  $negativeOffset: calc((100% - 1260px) / -2);
  #header, #main, #footer, #favoriteList {
    padding-left: $offset;
    padding-right: $offset;
  }

  .extend {
    margin-left: -90px;
    margin-right: -90px;
    padding-left: 90px;
    padding-right: 90px;
  }

  .full {
    margin-left: calc((100vw - 1260px) / -2);
    margin-right: calc((100vw - 1260px) / -2);
  }
}

.long-text {
  p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  ul {
    list-style-position: inside;
    list-style-type: none;
    transform: translateX(17px);

    > li {
      position: relative;
      padding-left: 10px;
    }

    > li:before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      border-radius: 10px;
      background-color: black;
      position: absolute;
      top: .9em;
      transform: translateX(-27px);

    }

    ul {
      margin-bottom: 20px;
      list-style: none;
      //transform: translateX(0px);

      li:before {
        height: 1px;
        width: 5px;
        padding: 0;
      }
    }

    li {
    }
  }

  h2 {
    font-size: 20px;
    margin: 40px auto 20px;

  }

  h3 {
    font-size: 16px;
    display: inline-block;
  }
}

.clickable {
  cursor: pointer;
}

#hd-mobile {
  display: flex;
  justify-content: space-between;

  .menu-burger {
    height: 56px;
  }
}

#hd-mobile-list {
  display: flex;
  margin: 10px 0;


  .hd-mobile-list-item {
    margin: auto 10px auto 0;
  }

  .hd-mobile-list-item-back {
    margin-right: 0;

    .back-button {
      margin-right: 10px;
    }
  }

  .hd-mobile-list-item-last {
    justify-self: flex-end;
  }
}

.talent-manager,
.talent-developer {
  padding-bottom: 100px;
  text-align: left;

  .talent-project {
    margin-bottom: 100px;
  }

  .talent-project-name {
    font-size: 26px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .talent-project-desc {
    font-size: 16px;
    font-weight: 400;
  }

  h2 {
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 800;
    margin: 50px 0;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: 600;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .developer-poste {
    text-transform: uppercase;
    font-weight: 600;
  }

  .developer-contrat {
    font-weight: 600;
    margin-left: 10px;
  }

  .developer-firm {
    font-weight: 600;

    &:before {
      content: '-';
      display: inline-block;
      margin: 0 10px;
    }

    text-transform: uppercase;
  }

  .developer-date {
    margin-bottom: 5px;

    span {
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;

      &:last-of-type {
        &:before {
          content: "-";
          margin: 0 10px;
        }
      }
    }
  }
}

.is-mobile, .is-tablet {
  .talent-manager,
  .talent-developer {
    padding-bottom: 20px;
  }

  .bottomNavTools {
    margin-top: 20px;
  }

  .talent-box .logo {
    margin-top: 40px;
  }
}

.cv {
  padding-left: 110px;
  margin-bottom: 40px;

  ul {
    margin-top: 20px;

    li {
      &:empty {
        display: none;
      }
    }
  }
}

.is-mobile, .is-tablet {
  .talent-developer,
  .talent-manager {
    h2 {
      margin: 10px 0;
    }

    .developer-firm {
      display: block;
      margin-left: 0;
    }

    .developer-firm:before,
    .developer-firm:before {
      display: none;
    }
  }

  .cv {
    padding-left: 0;

    ul {
      margin-top: 0;
    }
  }

  .long-text h3 {
    margin: 10px 0 0;
  }
}

.long-text {
  max-width: 960px;
  margin: 0 auto;
}

.bt {
  background-color: black;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  border: none;
  padding: 5px 10px;

  &:disabled {
    background-color: #f3f3f3;
    color: rgba(0, 0, 0, .8)
  }
}

.btBig {
  @extend .bt;
  padding: 10px 20px;
}

.link {
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.adminForm {

  &.loading {
    position: relative;

    fieldset {
      opacity: 0.2;
    }

    &:before {
      content: "";
      height: 64px;
      width: 64px;
      background: url('/img/spinner.gif') no-repeat center center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-64px);
    }

  }

  .adminFormLine {
    display: flex;

    &.adminFormLine_error {
      display: none;
    }

    input:not([type="file"]), textarea, select {
      line-height: 50px;
      padding: 0 20px;
      border: 1px solid #000;
      font-size: 14px;
      font-weight: 400;
      border-right-width: 0;

      &:last-of-type {
        border-right-width: 1px;
      }

      &::placeholder {
        text-transform: uppercase;
      }

      &:disabled {
        background-color: #F3F3F3;
        cursor: not-allowed;
      }

      &:invalid {
        border-color: red;
      }
    }

    textarea {
      padding: 10px 20px;
      line-height: 1.25rem;
      resize: none;
      background-color: #F3F3F3;
      border-color: #F3F3F3;
    }

    .radioButton, .checkboxButton, input:not([type="file"]), textarea, select {
      flex: 1;
    }

    &.adminFormLine_error {
      .radioButton, .checkboxButton, input:not([type="file"]), textarea, select {
        border-color: red;
      }
    }
  }

  &.adminForm_error {
    .adminFormLine.adminFormLine_error {
      display: block;

      p {
        color: red;
        padding: 10px 5px;
        margin: 0;
      }

    }
  }

  .adminFormLine:not(.adminFormSpacer) + .adminFormLine {
    .radioButton, .checkboxButton, .uploadButton, input:not([type="file"]), textarea, select {
      border-top-width: 0;
    }
  }

  .adminFormGroup {
    display: flex;
    margin-bottom: 10px;

    .radioButton, .checkboxButton {
      flex: 1;
    }
  }

  .adminFormSubmit {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
  }

  .labelTitle {
    display: block;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .radioButton {
    position: relative;

    span {
      padding-left: 30px;
      cursor: pointer;

      &:before, &:after {
        position: absolute;
      }

      &:before {
        content: '';
        border-radius: 10px;
        width: 20px;
        height: 20px;
        border: 1px solid black;
        background-color: transparent;
        left: 0;
        top: 0;
      }

      &:after {
        content: '';
        border-radius: 10px;
        width: 10px;
        height: 10px;
        background-color: black;
        opacity: 0;
        transition: 350ms;
        transform: scale(0);
        left: 5px;
        top: 5px;
      }
    }

    > input {
      position: absolute;
      right: 0;
      z-index: -1;
      opacity: .0000001;
    }

    input:checked + span {
      &:after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .checkboxButton {
    @extend .radioButton;

    span {
      &:before, &:after {
        border-radius: 0;
      }
    }
  }

  .uploadButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    line-height: 50px;
    padding: 0 20px;
    border: 1px solid #000;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;

    span {
      line-height: initial;
      padding: 16px 0px;
      text-transform: uppercase;

      small {
        display: block;
        text-transform: initial;
      }
    }

    .link, label {
      margin: 0;
      font-size: 10px;
      line-height: 50px;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    input {
      -webkit-appearance: none;
      font-size: 10px;
      background-color: transparent;
      border: none;
      /*position: absolute;
      z-index: -1;
      opacity: .1;*/
    }
  }

  .adminFormSpacer {
    margin-bottom: 50px;
  }

  .adminFormSpacerSmall {
    margin-bottom: 25px;
  }
}

.modal {
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close {
    background-color: transparent;
    font-size: 36px;
    line-height: 1;
    font-family: monospace;
    padding: 0;
    margin: 0;
    border: none;
  }
}
