@import "@/assets/styles/variables.scss";













































































































































































































#header {
  .header-logo .logo-loader {
    height: 200px;
    width: 200px;
  }
}

#header_top {
  display: flex;
  flex-direction: row;
  transition: 250ms 100ms;
  padding-top: 40px;

  a:not([itemprop]) {
    font-weight: 600;
  }

  &:not(.panel) {
    margin: 5px auto 30px;
  }


  [href] {
    color: black;
  }

  & + .back-button {
    position: absolute;
    transform: translateY(-50px);
  }

  .inline-list {
    margin-top: -10px;
  }

  .inline-list li {
    line-height: 44px;
  }

  ul:last-of-type {
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    li:last-of-type {
      margin-right: 0;

      a {
        padding-right: 0;
      }
    }
  }
}

#header_top.panel {
  flex-direction: column;
  justify-content: space-between;

  &.is-mobile {
    h1 {
      font-size: 16px;
      margin: 20px 0;
    }

    #header_top_mobile a span {
      font-size: 14px;
    }

    #header_top_mobile {
      display: grid;
      grid-gap: 4px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(16, 20px);
    }
  }

  &.is-tablet {
    h1 {
      font-size: 24px;
    }

    #header_top_mobile a span {
      font-size: 22px;
    }
  }

  h1 {
    font-weight: 700;
    margin: 50px 0;
    text-transform: uppercase;
    min-width: 100%;
    text-align: center;
  }
}

#header_top_mobile {
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(17, 40px);

  a {
    display: flex;

    span {
      margin: auto;
      font-weight: 600;
      text-transform: uppercase;
      max-width: 60%;
      text-align: center;
    }

    svg {
      margin: auto;
    }
  }

  #header_top_mobile_phone {
    background-color: $pale-red;
    grid-column: 1;
    grid-row-start: 2;
    grid-row-end: 7;
  }

  #header_top_mobile_about {
    background-color: $dcr-blue;
    grid-column: 1;
    grid-row-start: 7;
    grid-row-end: 12;
  }

  #header_top_mobile_email {
    background-color: $pale-green;
    grid-column: 2;
    grid-row-start: 1;
    grid-row-end: 6;
  }

  #header_top_mobile_home {
    background-color: $pale-yellow;
    grid-column: 2;
    grid-row-start: 6;
    grid-row-end: 12;
  }

  #header_top_mobile_connect {
    grid-column: span 2;
  }

  #header_top_mobile_connect {
    background-color: $pale-violet;
    text-transform: uppercase;
    grid-column: span 2;
    grid-row-start: 12;
    grid-row-end: 14;
  }

  #header_top_mobile_favorite {
    background-color: $pale-green;
    text-transform: uppercase;
    grid-column: span 2;
    grid-row-start: 14;
    grid-row-end: 16;
  }

  #header_top_mobile_mentions {
    background-color: #eee;
    grid-row-start: 16;
    grid-row-end: 18;
  }

  #header_top_mobile_confidentialite {
    background-color: #eee;
    grid-column: 2;
    grid-row-start: 16;
    grid-row-end: 18;
  }
}

.menu a {
  font-weight: 600;
}

#connection {
  a {
    margin-right: 0;
    padding: 10px 20px;
    justify-self: flex-end;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
  }
}

#header-mobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  margin-right: -20px;
  padding-right: 20px;
  margin-left: -20px;
  padding-left: 20px
}

#header_bottom {
  position: absolute;
  transform: translateY(-50px);
  display: flex;
  align-items: center;
}

#favoritesMobile {
  position: relative;
  width: 36px;
  height: 36px;
  background-color: $dcr-blue;
  border-radius: 50px;
  display: inline-flex;
  justify-content: space-around;
  cursor: pointer;

  & > svg {
    margin: auto;
  }
}

.favoritesMobile_length {
  position: absolute;
  background-color: white;
  width: 22px;
  height: 22px;
  top: -4px;
  right: -11px;
  border-radius: 50px;
  border: 1px solid black;
  line-height: 22px;
  text-align: center;
  font-size: 10px;
}

#header .desktopBack {
  position: absolute;
  transform: translateY(60px);
}

#openFavorites {
  position: relative;
}

#favoritesItem {
  span:first-child {
    margin-right: 10px;
  }
}

html:not(.is-desktop) {
  $gap: 5px;

  #menuPanel {
    .logo {
      display: flex;
      height: calc(32vh - (17 * #{$gap}));

      a {
        margin: 53px 40px;
      }
    }

    .navGrid {
      display: grid;
      grid-gap: $gap;
      margin: 0 $gap;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(17, 4vh);

      > a, > button {
        display: flex;

        svg, span {
          margin: auto;
        }

        span {
          display: block;
          color: #000;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          text-align: center;
        }

        &:nth-child(1) {
          grid-column: 1;
          grid-row: 2 / span 6;
          background-color: #FFE2D5;
        }

        &:nth-child(2) {
          grid-column: 2;
          grid-row: 1 / span 6;
          background-color: #D9F8C5;
        }

        &:nth-child(3) {
          grid-column: 1;
          grid-row: 8 / span 6;
          background-color: #96DDD9;
        }

        &:nth-child(4) {
          grid-column: 2;
          grid-row: 7 / span 7;
          background-color: #FAF7C0;
        }

        &:nth-child(5) {
          grid-column: 1 / span 2;
          grid-row: 14 / span 2;
          background-color: #D6DBFD;
        }

        &:nth-child(6) {
          grid-column: 1;
          grid-row: 16 / span 2;
          background-color: #f3f3f3;
        }

        &:nth-child(7) {
          grid-column: 2;
          grid-row: 16 / span 2;
          background-color: #f3f3f3;
        }
      }
    }
  }
}

@media screen and (orientation: landscape) {
  html:not(.is-desktop) {
    $gap: 5px;

    #menuPanel {
      .logo {
        display: flex;
        height: calc(49vh - (17 * #{$gap}));

        a {
          margin: auto #{$gap * 10};
        }
      }

      .navGrid {
        grid-template-rows: repeat(17, 3vh);
        margin: 0 #{$gap * 10};
      }
    }
  }
}
