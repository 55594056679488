@import "@/assets/styles/variables.scss";











































































.media-box img {
  background: #E2E2E2;
}

#mediaModal {
  .modal-dialog {
    min-height: 90vh;
    max-height: 90vh;
    min-width: 90vw;
    max-width: 90vw;
  }
}
