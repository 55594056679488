@import "@/assets/styles/variables.scss";
































.modal-full.modal {
  border: 0;
  width: 100vw;
  height: 100vh;

  .modal-dialog {
    margin: 0;
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;

    .modal-content {
      height: 100vh;
    }
  }

  .modal-header {
    border: none;
    padding: 1rem 1rem 0;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
